<template>
  <div class="wrapper">
    <div class="main">
      <PageNavbar />
      <main class="content">
        <div class="container">
          <!--<PageHeader v-if="!$route.meta.hidePageHeader" />-->
          <PageHeader v-if="false" />
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import PageNavbar from '@/components/PageStructure/PageNavbar.vue';
import PageHeader from '@/components/PageStructure/PageHeader.vue';

export default {
  name: 'DashboardView',
  watch: {
    $route(to) {
      document.title = `${to.meta.title || '404'}`;
    },
  },
  components: {
    PageNavbar,
    PageHeader,
  },
};
</script>
