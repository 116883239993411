<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
html,
body {
  height: 100%;
}

main.content {
  margin: 30px 0;
}
</style>
