<template>
  <b-navbar toggleable="md" type="dark" variant="dark">
    <b-container>
      <b-navbar-brand href="#">Redburger</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown text="Menús">
            <b-dropdown-item :to="{ name: 'menuAdd' }">Crear menú</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'menuList' }">Menús guardados</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'menuEdit' }">Cambiar stock</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item :to="{ name: 'pastaEdit' }">Pasta del día</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item :to="{ name: 'authSignOut' }"><i class="align-middle mr-1 fas fa-fw fa-arrow-alt-circle-right"></i> Cerrar sesión</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
import { AmplifyEventBus } from 'aws-amplify-vue';

export default {
  name: 'PageNavbarComp',
  data() {
    return {
      signedIn: false,
    };
  },
  async beforeCreate() {
    try {
      await this.$Amplify.Auth.currentAuthenticatedUser();
      this.signedIn = true;
    } catch (err) {
      this.signedIn = false;
    }
    AmplifyEventBus.$on('authState', (info) => {
      this.signedIn = info === 'signedIn';
    });
  },
};
</script>

<style>
.bg-dark {
  background: linear-gradient(to bottom, #bf0000 0%, #8f0101 100%);
}
</style>
