<template>
  <div class="menu-form">
    <div class="header text-center">
      <h1>Menú del día</h1>
      <p>Herramienta para la creación del menú</p>
    </div>
    <hr/>
    <div class="row justify-content-center" v-if="errored || loading">
      <div class="alert alert-danger" v-if="errored">
        Actualmente no hay ningún menú publicado en la tienda.
      </div>
      <Spinner v-else-if="loading" />
    </div>
    <form id="menuform" @submit="formSubmit" autocomplete="off" v-else>
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">Primeros platos</h5>
          <div class="form-group" v-for="(primero, index) in menu.primeros" :key="index">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input type="checkbox" :aria-label="primero.name" v-model="primero.enabled">
                </div>
              </div>
              <input class="form-control" autocomplete="off" type="text" :disabled="!primero.enabled" readonly name="primeros" :value="primero.name">
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">Segundos platos</h5>
          <div class="form-group" v-for="(segundo, index) in menu.segundos" :key="index">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input type="checkbox" :aria-label="segundo.name" v-model="segundo.enabled">
                </div>
              </div>
              <input class="form-control" autocomplete="off" type="text" :disabled="!segundo.enabled" readonly name="segundos" :value="segundo.name">
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">Postres</h5>
          <div class="form-group" v-for="(postre, index) in menu.postres" :key="index">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input type="checkbox" :aria-label="postre.name" v-model="postre.enabled">
                </div>
              </div>
              <input class="form-control" autocomplete="off" type="text" :disabled="!postre.enabled" readonly name="postres" :value="postre.name">
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding:10px">
        <input type="hidden" name="type" value="dia"/>
        <input class="btn btn-success btn-lg btn-block" id="btnsend" name="enviar" type="submit" value="Enviar"/>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/helpers/httpInterceptor';

export default {
  name: 'MenuFormView',
  data() {
    return {
      errored: false,
      loading: true,
      menu: {
        primeros: [],
        segundos: [],
        postres: [],
      },
    };
  },
  mounted() {
    this.retrieveData();
  },
  methods: {
    retrieveData() {
      api
        .get('/api/v3/store/menu')
        .then((response) => {
          this.menu.primeros = response.data.primero.value.split(' | ').slice(1).map(((e) => ({ name: e, enabled: true })));
          this.menu.segundos = response.data.segundo.value.split(' | ').slice(1).map(((e) => ({ name: e, enabled: true })));
          this.menu.postres = response.data.postre.value.split(' | ').slice(1).map(((e) => ({ name: e, enabled: true })));
          this.loading = false;
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            this.errored = true;
          } else {
            console.log(error);
          }
          this.loading = false;
        });
    },
    formSubmit(e) {
      e.preventDefault();
      api
        .post('/api/v3/store/menu', {
          primeros: this.menu.primeros.filter((e) => e.enabled).map((e) => e.name),
          segundos: this.menu.segundos.filter((e) => e.enabled).map((e) => e.name),
          postres: this.menu.postres.filter((e) => e.enabled).map((e) => e.name),
        })
        .then(() => {
          this.$bvModal.msgBoxOk('El stock del menú ha sido actualizado en la tienda correctamente.')
            .then(() => {
              this.$router.push({ name: 'menuList' });
            });
        })
        .catch(() => {
          this.$bvModal.msgBoxOk('Se produjo un error actualizando el stock del menú en la tienda.');
        });
    },
  },
};
</script>
