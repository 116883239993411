import axios from 'axios';
import { Auth } from 'aws-amplify';

const api = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API || 'http://localhost:3000',
});

api.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();
  const { jwtToken } = session.accessToken;

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${jwtToken}`,
    },
  };
});

export default api;
