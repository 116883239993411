import Vue from 'vue';
import Vuex from 'vuex';
import createPersist from 'vuex-localstorage';

// Modules
import auth from './modules/auth';
// import settings from './modules/settings';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  modules: {
    auth,
    // settings,
  },
  strict: debug,
  plugins: [
    createPersist({
      namespace: 'redburger',
      initialState: {},
      expires: 7 * 24 * 60 * 60 * 1e3, // ONE_WEEK
    }),
  ],
});

export default store;
