<template>
  <div class="justify-content-md-center">
    <div class="col col-md-8">
      <h2>Sign Out...</h2>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router';

export default {
  async created() {
    await this.$store.dispatch('auth/signOut');
    router.push('/');
  },
};
</script>
