<template>
  <div class="row justify-content-center" v-if="errored || loading">
    <div class="alert alert-danger" v-if="errored">
      Ha ocurrido un error inexperado.
    </div>
    <Spinner v-else-if="loading" />
  </div>
  <div class="menu-form" v-else>
    <div class="header text-center">
      <h1>Pasta del día</h1>
      <p>Herramienta para cambiar la pasta del día</p>
    </div>
    <hr/>
    <form id="menuform" @submit="formSubmit" autocomplete="off">
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">Pasta del día</h5>
          <b-form-select :options="dataTypes" v-model="dataActive.type" size="lg" required></b-form-select>
        </div>
      </div>
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">Salsa del día</h5>
          <b-form-select :options="dataSauces" v-model="dataActive.sauce" size="lg" required></b-form-select>
        </div>
      </div>
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">Salsas disponibles</h5>
          <div class="form-group" v-for="(sauce, index) in dataSauces" :key="index">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input type="checkbox" :aria-label="sauce.value" v-model="sauce.enabled" :data-value="sauce.value" @change="toggleSauce">
                </div>
              </div>
              <input class="form-control" type="text" name="sauces" readonly :disabled="sauce.disabled" :value="sauce.value"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding:10px">
        <input type="hidden" name="type" value="dia"/>
        <input class="btn btn-success btn-lg btn-block" id="btnsend" name="enviar" type="submit" value="Enviar"/>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/helpers/httpInterceptor';

export default {
  name: 'PastaFormView',
  data() {
    return {
      loading: true,
      errored: false,
      dataSauces: [],
      dataTypes: [],
      dataActive: {
        type: null,
        sauce: null,
      },
    };
  },
  mounted() {
    this.retrieveData();
  },
  methods: {
    toggleSauce(e) {
      const { checked } = e.target;
      const { value } = e.target.dataset;
      const sauceIndex = this.dataSauces.findIndex((sauce) => sauce.value === value);

      this.dataSauces[sauceIndex].enabled = checked;
      this.dataSauces[sauceIndex].disabled = !checked;
    },
    retrieveData() {
      Promise.all([
        api.get('/api/v3/data/pasta/types'),
        api.get('/api/v3/data/pasta/sauces'),
      ])
        .then(([types, sauces]) => {
          this.dataTypes = types.data.types.map((type) => ({
            value: type, text: type, disabled: false, enabled: true,
          }));
          this.dataSauces = sauces.data.sauces.map((sauce) => ({
            value: sauce, text: sauce, disabled: !sauces.data.available.includes(sauce), enabled: sauces.data.available.includes(sauce),
          }));
          this.dataActive = {
            type: types.data.active,
            sauce: sauces.data.active,
          };
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false));
    },
    formSubmit(e) {
      e.preventDefault();
      this.dataActive.available_sauces = this.dataSauces.filter((sauce) => sauce.enabled).map((sauce) => sauce.value);
      console.log(this.dataActive);
      api
        .post('/api/v3/data/pasta/active', this.dataActive)
        .then(() => {
          this.$bvModal.msgBoxOk('La configuración de la pasta ha sido guardado correctamente.')
            .then(() => {
              this.$router.push({ name: 'pastaEdit' });
            });
        })
        .catch(() => {
          this.$bvModal.msgBoxOk('Se produjo un error configurando la pasta.');
        });
    },
  },
};
</script>
