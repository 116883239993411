<template>
  <div v-if="hasAuthenticationStatus" class="alert alert-dismissible" v-bind:class="'alert-' + authenticationStatus.variant" role="alert">
    <div class="alert-icon"><i class="far fa-fw fa-bell"></i></div>
    <div class="alert-message">{{ authenticationStatus.message }}.</div>

    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  mounted() {
    // clear existing status message
    this.$store.dispatch('auth/clearAuthenticationStatus');
  },
  computed: {
    ...mapGetters('auth', ['authenticationStatus', 'hasAuthenticationStatus']),
  },
};
</script>
