<template>
  <div id="home">
  </div>
</template>

<script>
export default {
  name: 'Home',
  methods: {},
  beforeCreate() {
    this.$router.push({ name: 'menuList' });
  },
};
</script>

<style lang="scss">
</style>
