<template>
  <div class="row justify-content-center" v-if="errored || loading">
    <div class="alert alert-danger" v-if="errored">
      Ha ocurrido un error inexperado.
    </div>
    <Spinner v-else-if="loading" />
  </div>
  <div class="menu-list" v-else>
    <b-calendar block locale="es-ES" @selected="retrieveMenu" :date-disabled-fn="dateDisabled" hide-header
                label-help="" start-weekday="1" v-model="selectedDate"></b-calendar>
    <button @click="$router.push({ name: 'menuAdd' })" class="btn btn-block btn-success btn-sm mt-3">Añadir menú</button>
    <button @click="downloadMenu" class="btn btn-block btn-info btn-sm mt-3">Descargar menú</button>
    <button @click="deleteMenu" class="btn btn-block btn-danger btn-sm mt-3" v-if="menu">Eliminar menú</button>
    <hr>
    <div class="entry-content" v-if="menu">
      <div id="dia">
        <h1>Menú del día</h1>
        <p class="fecha">{{ menu.menu[0].fecha }}</p>
        <hr>
        <h2>Primeros</h2>
        <p> · {{ menu.menu[0].primeros.pri_uno }} ·</p>
        <p> · {{ menu.menu[0].primeros.pri_dos }} ·</p>
        <p> · {{ menu.menu[0].primeros.pri_tres }} ·</p>
        <p class="small" v-if="menu.menu[0].primeros.pri_opc1">· {{ menu.menu[0].primeros.pri_opc1 }} ·</p>
        <p class="small" v-if="menu.menu[0].primeros.pri_opc2">· {{ menu.menu[0].primeros.pri_opc2 }} ·</p>
        <p class="small" v-if="menu.menu[0].primeros.pri_opc3">· {{ menu.menu[0].primeros.pri_opc3 }} ·</p>
        <p v-if="!menu.menu[0].primeros.pri_opc1"><br></p>
        <p v-if="!menu.menu[0].primeros.pri_opc2"><br></p>
        <p v-if="!menu.menu[0].primeros.pri_opc3"><br></p>
        <h2>Segundos</h2>
        <p> · {{ menu.menu[0].segundos.seg_uno }} ·</p>
        <p> · {{ menu.menu[0].segundos.seg_dos }} ·</p>
        <p> · {{ menu.menu[0].segundos.seg_tres }} ·</p>
        <h2>Postres</h2>
        <p class="postre" v-if="menu.menu[0].postres.pos_tqueso">· TARTA DE QUESO ·</p>
        <p class="postre" v-if="menu.menu[0].postres.pos_arroz">· ARROZ CON LECHE ·</p>
        <p class="postre" v-if="menu.menu[0].postres.pos_choco">· CREMA DE CHOCOLATE ·</p>
        <p class="postre" v-if="menu.menu[0].postres.pos_mousse">· MOUSSE DE LIMÓN ·</p>
        <p class="postre" v-if="menu.menu[0].postres.pos_flan">· FLAN DE HUEVO ·</p>
        <p class="postre" v-if="menu.menu[0].postres.pos_natillas">· NATILLAS ·</p>
        <p class="postre" v-if="menu.menu[0].postres.pos_tmanzana">· TARTA DE MANZANA ·</p>
        <p class="postre" v-if="menu.menu[0].postres.pos_helado">· HELADO ·</p>
        <p class="postre" v-if="menu.menu[0].postres.pos_fruta">· FRUTA DEL TIEMPO ·</p>
        <hr>
        <p class="incluye">Incluye: <strong>VINO DE LA CASA | GASEOSA | AGUA | PAN | CAFÉ</strong></p>
        <p class="refrescos">Refrescos no incluidos (suplemento: 1€)</p>
        <p class="precioh">Precio:</p>
        <p class="precio">{{ menu.menu[0].precio }}€</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/httpInterceptor';

export default {
  name: 'MenuListView',
  data() {
    return {
      errored: false,
      loading: true,
      calendar: [],
      menu: null,
      selectedDate: (new Date().toISOString()).substr(0, 10),
    };
  },
  mounted() {
    this.retrieveCalendar();
    this.retrieveMenu(this.selectedDate);
  },
  methods: {
    dateDisabled(ymd) {
      return !(this.calendar.indexOf(ymd) > -1);
    },
    retrieveCalendar() {
      api
        .get('/api/v3/menu/calendar')
        .then((response) => {
          this.calendar = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false));
    },
    deleteMenu() {
      this.$bvModal.msgBoxConfirm('¿Está seguro que desea eliminar este menú?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Eliminar',
        cancelTitle: 'Cancelar',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((proceed) => {
          if (proceed) {
            api
              .delete(`/api/v3/menu/${this.selectedDate}`)
              .then(() => {
                this.$bvModal.msgBoxOk('El menú ha sido eliminado correctamente.')
                  .then(() => {
                    this.$router.go();
                  });
              })
              .catch(() => {
                this.$bvModal.msgBoxOk('Hubo un error al realizar la solicitud.', {
                  title: 'Oops!',
                  size: 'sm',
                  buttonSize: 'sm',
                  okVariant: 'success',
                  headerClass: 'p-2 border-bottom-0',
                  footerClass: 'p-2 border-top-0',
                  centered: true,
                });
              });
          }
        })
        .catch(() => {
          // This will be triggered when user clicks on cancel
        });
    },
    downloadMenu() {
      api
        .get(`/api/v3/menu/pdf/${this.selectedDate}`)
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `MenuRedburger-${this.selectedDate}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(() => {
          this.$bvModal.msgBoxOk('Hubo un error al realizar la solicitud.', {
            title: 'Oops!',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
        });
    },
    retrieveMenu(date) {
      api
        .get(`/api/v2/menu/${date}`)
        .then((response) => {
          this.selectedDate = date;
          this.menu = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
@font-face {
  font-display: swap;
  font-family: 'Champagne';
  src: url('/fonts/champagne_and_limousines.eot');
  src: url('/fonts/champagne_and_limousines.eot?#iefix') format('embedded-opentype'), url('/fonts/champagne_and_limousines.svg#Champagne & Limousines') format('svg'), url('/fonts/champagne_and_limousines.woff') format('woff'), url('/fonts/champagne_and_limousines.ttf') format('truetype');
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-display: swap;
  font-family: "Canterbury";
  src: url('/fonts/Canterbury.ttf'), url('/fonts/Canterbury.eot')
}

@font-face {
  font-display: swap;
  font-family: "BrushScript";
  src: url('/fonts/BrushScript.ttf'), url('/fonts/BrushScript.eot')
}

@font-face {
  font-display: swap;
  font-family: "Impact";
  src: url('/fonts/Impact.ttf'), url('/fonts/Impact.eot')
}

@font-face {
  font-display: swap;
  font-family: "BebasNeue";
  src: url('/fonts/BebasNeue.ttf'), url('/fonts/BebasNeue.eot')
}

.entry-content {
  padding: 10px;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  text-align: center
}

#dia h1 {
  font-family: 'BrushScript'
}

#dia h2 {
  font-family: 'Canterbury';
  margin-top: 0 !important;
}

#dia p {
  line-height: 15px
}

#dia p.fecha {
  font-style: italic;
  font-weight: bold
}

#dia p.postre {
  font-size: 85%;
  line-height: 12px
}

#dia p.incluye, #dia p.refrescos {
  font-style: italic;
  font-size: 8pt
}

#dia p.refrescos {
  font-size: 8pt
}

#dia p.precioh {
  font-size: 85%;
  line-height: 25px
}

#dia p.precio {
  font-size: 30px;
  font-family: Impact;
  line-height: 0;
  margin-bottom: 20px !important
}

#special {
  color: #333 !important
}

#special hr {
  margin: 20px 0 !important
}

#special h1, #special h2, #special h3 {
  margin-top: 20px !important;
  margin-bottom: 10px !important
}

#special h1 {
  font-family: 'Canterbury';
  font-size: 50px;
}

#special h2 {
  font-family: 'Canterbury';
  margin-top: 0 !important;
}

#special h3 {
  font-family: "BebasNeue";
  font-size: 35px;
  background: #FFF !important;
  width: 160px;
  margin: -15px auto 0 auto !important;
  z-index: 1;
  position: relative
}

#special h3.postreh {
  font-size: 40px
}

#special hr.special {
  border-bottom-color: rgb(51, 51, 51);
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(51, 51, 51);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(51, 51, 51);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgb(51, 51, 51);
  border-top-style: solid;
  border-top-width: 15px;
  box-sizing: content-box;
  color: rgb(128, 128, 128);
  font-family: "Tahoma";
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-size: 14.6667px;
  font-size-adjust: none;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variant-position: normal;
  font-weight: 400;
  height: 0px;
  line-height: 18.5px;
  margin: 20px auto -30px auto !important;
  position: relative;
  text-align: center;
  top: -70px;
  width: 485px;
}

#special p {
  line-height: 15px;
  margin-bottom: 10px !important
}

#special p.plato {
  font-family: 'Champagne';
  font-size: 29px;
  line-height: 30px;
}

#special p.comp {
  font-family: 'Champagne';
  font-size: 18px;
  margin-bottom: 21px !important
}

#special p.fecha {
  font-style: italic;
  font-weight: bold
}

#special .postres {
  width: 100%;
  margin: 0 auto !important
}

#special .postres p {
  font-size: 8pt;
  line-height: 10px;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 50%;
  float: left
}

#special p.incluye, #special p.refrescos {
  font-style: italic;
  font-size: 8pt;
  margin: 0 !important
}

#special p.refrescos {
  font-size: 8pt
}

#special p.precioh {
  font-size: 85%;
  line-height: 25px
}

#special p.precio {
  font-size: 30px;
  font-family: 'Impact';
  line-height: 0
}

#error i {
  font-size: 60px;
  margin: 10px
}

#error h1 {
  font-size: 50px;
  font-style: italic
}

#error h2 {
  font-size: 22px
}

.buttons {
  margin: 10px auto 0 auto
}

.buttons .row {
  margin: 0
}

#btn-back, #btn-print, #btn-app {
  margin: 4px 0
}

@media (min-width: 992px) {
  .entry-content {
    width: 21cm;
    padding: 1cm;
    margin: 10px auto 1cm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    text-align: center
  }

  #dia h1 {
    font-size: 90px
  }

  #dia h2 {
    font-family: 'Canterbury';
    font-size: 55px;
  }

  #dia p.incluye, #dia p.refrescos {
    font-size: 9pt
  }

  #dia p.precioh {
    line-height: 40px
  }

  #dia p.precio {
    font-size: 45px;
    font-family: 'Impact'
  }

  #special h1 {
    font-size: 90px
  }

  #special h2 {
    font-size: 50px
  }

  #special h3 {
    font-size: 50px
  }

  #special hr.special {
    top: -70px;
    width: 485px
  }

  #special .postres {
    width: 400px
  }

  #special .postres p {
    font-size: 85%;
    line-height: 8px
  }

  #special p.incluye, #special p.refrescos {
    font-size: 9pt
  }

  #special p.precioh {
    line-height: 35px
  }

  #special p.precio {
    font-size: 35px
  }

  .buttons {
    width: 21cm;
    margin: 1cm auto 0 auto
  }
}

@page {
  size: 297mm 420mm;
  margin: 0
}

@media print {
  .site-footer, .site-header {
    display: none
  }

  .entry-content {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: avoid;
    display: block !important;
  }

  #special hr.special {
    border-color: #000;
  }

  .buttons, #error {
    display: none
  }

  #dia hr {
    width: 50%;
    border-color: #373737
  }

  #dia h1 {
    font-size: 110px;
    margin-top: 15px;
  }

  #dia h2 {
    font-family: 'Canterbury';
    font-size: 80px;
  }

  #dia p {
    font-size: 28px;
    line-height: 38px;
  }

  #dia p.incluye, #dia p.refrescos {
    font-size: 18px;
    line-height: 24px;
  }

  #dia p.precioh {
    font-size: 16px;
  }

  #dia p.postre {
    font-size: 26px;
    line-height: 22px;
  }

  #dia p.precio {
    font-size: 65px;
    font-family: 'Impact';
    margin-top: 30px;
  }

  #special h1 {
    font-size: 120px
  }

  #special h2 {
    font-size: 70px
  }

  #special h3 {
    font-size: 80px;
    width: 250px;
  }

  #special p.plato {
    font-size: 40px;
    line-height: 50px;
  }

  #special p.comp {
    font-size: 28px;
    margin-bottom: 20px
  }

  #special .postres {
    width: 590px
  }

  #special .postres p {
    font-size: 18px;
    line-height: 10px
  }

  #special p.incluye, #special p.refrescos {
    font-size: 18px;
    line-height: 24px;
  }

  #special p.precioh {
    font-size: 16px;
  }

  #special p.precio {
    font-size: 55px;
    font-family: 'Impact';
    margin-top: 30px;
  }

  #special hr.special {
    margin-top: -30px;
    width: 700px;
    border-width: 12px 0 0;
    margin-bottom: 25px;
  }

  #special p.fecha {
    font-size: 23px;
  }
}
</style>
