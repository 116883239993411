import Vue from 'vue';
import VueRouter from 'vue-router';

import Dashboard from '@/views/Dashboard/_DashboardView.vue';
import Home from '@/views/Dashboard/Home.vue';

import MenuView from '@/views/Dashboard/Menu/_MenuView.vue';
import MenuList from '@/views/Dashboard/Menu/List.vue';
import MenuForm from '@/views/Dashboard/Menu/Form.vue';
import MenuStockForm from '@/views/Dashboard/Menu/StockForm.vue';

import PastaForm from '@/views/Dashboard/Pasta/Form.vue';

import AuthView from '@/views/Auth/_AuthView.vue';
import AuthSignIn from '@/views/Auth/SignIn.vue';
import AuthSignOut from '@/views/Auth/SignOut.vue';

import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Dashboard,
    meta: { title: 'Home' },
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: { title: 'Home', requiresAuth: true, hidePageHeader: true },
      },
      {
        path: '/menu',
        name: 'menu',
        component: MenuView,
        meta: { title: 'Menús', requiresAuth: true },
        children: [
          {
            path: '/menu/lista',
            name: 'menuList',
            component: MenuList,
            meta: { title: 'Listado de menús', requiresAuth: true },
          },
          {
            path: '/menu/crear',
            name: 'menuAdd',
            component: MenuForm,
            meta: { title: 'Crear un nuevo menú', requiresAuth: true },
          },
          {
            path: '/menu/editar',
            name: 'menuEdit',
            component: MenuStockForm,
            meta: { title: 'Editar el stock del menú', requiresAuth: true },
          },
        ],
      },
      {
        path: '/pasta',
        name: 'pasta',
        component: MenuView,
        meta: { title: 'Menús', requiresAuth: true },
        children: [
          {
            path: '/pasta/editar',
            name: 'pastaEdit',
            component: PastaForm,
            meta: { title: 'Cambiar la pasta del día', requiresAuth: true },
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
    meta: { title: 'Auth', requiresAuth: false },
    children: [
      {
        path: '/auth/signIn',
        name: 'authSignIn',
        component: AuthSignIn,
        meta: { title: 'Iniciar sesión', requiresAuth: false },
      },
      {
        path: '/auth/signOut',
        name: 'authSignOut',
        component: AuthSignOut,
        meta: { title: 'Cerrar sesión', requiresAuth: true },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // Use the page's router title to name the page
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} · Redburger Admin`;
  }

  // is there a meta and auth attribute?
  if (to.meta && to.meta.requiresAuth !== undefined) {
    // if the page requires auth
    if (to.meta.requiresAuth) {
      // and we are authenticated?
      if (store.getters['auth/isAuthenticated']) {
        next(); // route normally
        return;
      }
      // otherwise off to the sign in page
      router.push({ name: 'authSignIn' });
      return;
    }
    // otherwise are we already authenticated?
    if (store.getters['auth/isAuthenticated']) {
      // yes we are, so off to dashboard
      router.push({ name: 'home' });
      return;
    }
    next(); // route normally
    return;
  }
  next(); // route normally
});

export default router;
