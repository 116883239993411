<template>
  <div class="header">
    <h1 class="header-title">{{ $route.meta.title }}</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Inicio</router-link></li>
        <template v-for="(link, index) in breadcrumbs">
          <router-link v-bind:key="index" :to="link.to" v-slot="{ href, route, navigate, isActive, isExactActive }">
            <li :class="[isExactActive && 'active']" class="breadcrumb-item"><a :href="href" @click="navigate">{{ link.text }}</a></li>
          </router-link>
        </template>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'PageHeaderComp',
  computed: {
    breadcrumbs() {
      const pathArray = this.$route.path.split('/');
      pathArray.shift();
      const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path,
          to: breadcrumbArray[idx - 1]
            ? `/${breadcrumbArray[idx - 1].path}/${path}`
            : `/${path}`,
          text: this.$route.matched[idx + 1] ? this.$route.matched[idx + 1].meta.title : null || path,
        });
        return breadcrumbArray.slice(0, 2);
      }, []);
      return breadcrumbs;
    },
  },
};
</script>

<style>
  .breadcrumb-item.active a {
    color: #fff;
  }
</style>
