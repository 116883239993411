<template>
  <div class="menu-form">
    <div class="header text-center">
      <h1>Menú del día</h1>
      <p>Herramienta para la creación del menú</p>
    </div>
    <hr/>
    <form id="menuform" @submit="formSubmit" autocomplete="off">
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">Primeros platos</h5>
          <div class="form-group" v-for="index in numPrimeros" :key="index">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend"><span class="input-group-text">{{ index }}</span></div>
              <input class="form-control" autocomplete="off" type="text" :required="index === 1" name="primeros" >
            </div>
          </div>
          <div class="form-group" v-for="(fijo, index) in fijos" :key="'f' + index">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input type="checkbox" v-model="fijo.enabled" :aria-label="fijo.name">
                </div>
              </div>
              <input class="form-control" autocomplete="off" type="text" readonly :disabled="!fijo.enabled" name="primeros" :value="fijo.name"/>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">Segundos platos</h5>
          <div class="form-group" v-for="index in numSegundos" :key="index">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend"><span class="input-group-text">{{ index }}</span></div>
              <input class="form-control" autocomplete="off" type="text" :required="index === 1" name="segundos" >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">Postres</h5>
          <div class="form-group" v-for="(postre, index) in dataPostres" :key="index">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input type="checkbox" :aria-label="postre.name" :checked="postre.stock_status === 'instock'" :disabled="postre.stock_status === 'outofstock'">
                </div>
              </div>
              <input class="form-control" type="text" name="postres" readonly :disabled="postre.stock_status === 'outofstock'" :value="postre.name"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="padding:10px">
        <input type="hidden" name="type" value="dia"/>
        <input class="btn btn-success btn-lg btn-block" id="btnsend" name="enviar" type="submit" value="Enviar"/>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/helpers/httpInterceptor';

export default {
  name: 'MenuFormView',
  data() {
    return {
      numPrimeros: 3,
      numSegundos: 3,
      dataPostres: [
        { name: '', stock_status: '' },
      ],
      fijos: [
        { name: 'Espárragos dos salsas', enabled: true },
        { name: 'Pasta del día', enabled: true },
        { name: 'Ensalada de la casa', enabled: true },
      ],
      menu: {
        primeros: [],
        segundos: [],
        postres: [],
      },
    };
  },
  mounted() {
    this.retrieveDesserts();
    this.retrieveActivePasta();
  },
  methods: {
    retrieveDesserts() {
      api
        .get('/api/v3/store/products?category=24&order=asc&per_page=50')
        .then((response) => {
          this.dataPostres = response.data;
          this.dataPostres.push({ name: 'Fruta del tiempo', stock_status: 'instock' });
          this.dataPostres.push({ name: 'Helado', stock_status: 'instock' });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    retrieveActivePasta() {
      Promise.all([
        api.get('/api/v3/data/pasta/types'),
        api.get('/api/v3/data/pasta/sauces'),
      ])
        .then(([types, sauces]) => {
          const pastaActiveType = types.data.active;
          const pastaActiveSauce = sauces.data.active;
          const pastaActiveString = `${pastaActiveType} con ${pastaActiveSauce.toLowerCase()}`;
          const fijosPastaIndex = this.fijos.findIndex((fijo) => fijo.name === 'Pasta del día');
          this.fijos[fijosPastaIndex].name = pastaActiveString;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formSubmit(e) {
      e.preventDefault();
      this.menu.primeros = Array.from(e.target.elements.primeros.values()).filter((p) => p.value !== '' && !p.disabled).map((p) => p.value);
      this.menu.segundos = Array.from(e.target.elements.segundos.values()).filter((p) => p.value !== '' && !p.disabled).map((p) => p.value);
      this.menu.postres = Array.from(e.target.elements.postres.values()).filter((p) => p.value !== '' && !p.disabled).map((p) => p.value);
      api
        .post('/api/v3/menu', this.menu)
        .then(() => {
          this.$bvModal.msgBoxOk('El menú ha sido guardado correctamente.')
            .then(() => {
              this.$router.push({ name: 'menuList' });
            });
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            this.$bvModal.msgBoxOk('Ya hay un menú creado para hoy.');
          } else {
            this.$bvModal.msgBoxOk('Se produjo un error añadiendo el menú.');
          }
        });
    },
  },
};
</script>
