<template>
  <div id="auth-signin-view">
    <form class="form-signin" @submit.prevent="signIn">
      <img class="mb-4" src="@/assets/logo.png" width="72" height="72">
      <h1 class="h3 mb-3 font-weight-normal">Por favor, identifíquese</h1>
      <label for="inputUser" class="sr-only">Nombre de usuario</label>
      <input type="text" id="inputUser" class="form-control" placeholder="Nombre de usuario" v-model="user" required
             autofocus>
      <label for="inputPassword" class="sr-only">Contraseña</label>
      <input type="password" id="inputPassword" class="form-control" placeholder="Contraseña" v-model="pass" required>
      <div class="checkbox mb-3">
        <label>
          <input type="checkbox" value="remember-me" checked> Recordarme
        </label>
      </div>
      <button class="btn btn-lg btn-primary btn-block" type="submit">Iniciar sesión</button>
    </form>
    <v-alert />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import store from '@/store';

import Alert from '@/components/Auth/Alert.vue';

Vue.component('v-alert', Alert);

export default {
  data() {
    return {
      user: '',
      pass: '',
      newPass: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['hasAuthenticationStatus', 'passwordChangeRequired']),
  },
  methods: {
    async signIn() {
      if (!this.passwordChangeRequired) {
        await store.dispatch('auth/signIn', {
          username: this.user,
          password: this.pass,
        });
      } else {
        await store.dispatch('auth/requiredPasswordChange', {
          username: this.user,
          password: this.pass,
          newPassword: this.newPass,
        });
      }

      if (!this.hasAuthenticationStatus) {
        router.push({ name: 'home' });
      }
    },
  },
};
</script>

<style>
#auth-signin-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  text-align: center !important;
  height: 100%;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="user"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
