import { Auth, Logger } from 'aws-amplify';

// Logger.LOG_LEVEL = 'DEBUG'; // to show detailed logs from Amplify library
const logger = new Logger('store:auth');

// initial state
const state = {
  user: null,
  isAuthenticated: false,
  authenticationStatus: null,
  passwordChangeRequired: false,
};

const getters = {
  authenticatedUser: (state) => state.user,
  isAuthenticated: (state) => state.isAuthenticated,
  authenticationStatus: (state) => (state.authenticationStatus
    ? state.authenticationStatus
    : { variant: 'secondary' }),
  hasAuthenticationStatus: (state) => !!state.authenticationStatus,
  passwordChangeRequired: (state) => state.passwordChangeRequired,
};

const mutations = {
  setAuthenticationError(state, err) {
    logger.debug('auth error: {}', err);
    state.authenticationStatus = {
      state: 'failed',
      message: err.message,
      variant: 'danger',
    };
  },
  setPasswordChangeRequired(state, bool) {
    state.passwordChangeRequired = bool;
    state.authenticationStatus = {
      state: 'success',
      message: 'Your password needs to be changed',
      variant: 'info',
    };
  },
  clearAuthenticationStatus: (state) => {
    state.authenticationStatus = null;
    state.passwordChangeRequired = false;
  },
  setUserAuthenticated(state, params) {
    state.user = params.user;
    state.isAuthenticated = true;
  },
  clearAuthentication(state) {
    state.user = null;
    state.isAuthenticated = false;
  },
};

const actions = {
  clearAuthenticationStatus: (context) => {
    context.commit('clearAuthenticationStatus', null);
  },
  signIn: async (context, params) => {
    logger.debug(`signIn for ${params.username}`);
    context.commit('auth/clearAuthenticationStatus', null, { root: true });
    await Auth.signIn(params.username, params.password).then(async (user) => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        context.commit('auth/setPasswordChangeRequired', true, { root: true });
      } else {
        context.commit('setUserAuthenticated', { user });
      }
    }).catch((err) => {
      context.commit('auth/setAuthenticationError', err, { root: true });
    });
  },
  requiredPasswordChange: async (context, params) => {
    logger.debug(`requiredPasswordChange for ${params.username}`);
    context.commit('auth/clearAuthenticationStatus', null, { root: true });
    await Auth.signIn(params.username, params.password).then(async (user) => {
      await Auth.completeNewPassword(
        user,
        params.newPassword,
        {},
      ).then(async (loggedUser) => {
        context.commit('setUserAuthenticated', { loggedUser });
      });
    }).catch((err) => {
      context.commit('auth/setAuthenticationError', err, { root: true });
    });
  },
  signOut: async (context) => {
    try {
      await Auth.signOut();
    } catch (err) {
      logger.error('error during sign out: {}', err);
    }
    context.commit('auth/clearAuthentication', null, { root: true });
  },
  signUp: async (context, params) => {
    context.commit('auth/clearAuthenticationStatus', null, { root: true });
    try {
      await Auth.signUp(params);
      context.commit('auth/clearAuthentication', null, { root: true });
    } catch (err) {
      context.commit('auth/setAuthenticationError', err, { root: true });
    }
  },
  confirmSignUp: async (context, params) => {
    logger.debug('confirm signup for {}', params.username);
    context.commit('auth/clearAuthenticationStatus', null, { root: true });
    try {
      await Auth.confirmSignUp(params.username, params.code);
    } catch (err) {
      context.commit('auth/setAuthenticationError', err, { root: true });
    }
  },
  confirmResend: async (context, params) => {
    context.commit('auth/clearAuthenticationStatus', null, { root: true });
    try {
      await Auth.resendSignUp(params.username);
    } catch (err) {
      context.commit('auth/setAuthenticationError', err, { root: true });
    }
  },
  passwordReset: async (context, params) => {
    context.commit('auth/clearAuthenticationStatus', null, { root: true });
    try {
      await Auth.forgotPassword(params.username);
    } catch (err) {
      context.commit('auth/setAuthenticationError', err, { root: true });
    }
  },
  confirmPasswordReset: async (context, params) => {
    context.commit('auth/clearAuthenticationStatus', null, { root: true });
    try {
      await Auth.forgotPasswordSubmit(
        params.username,
        params.code,
        params.password,
      );
    } catch (err) {
      context.commit('auth/setAuthenticationError', err, { root: true });
    }
  },
  passwordResetResend: async (context, params) => {
    context.commit('auth/clearAuthenticationStatus', null, { root: true });
    try {
      await Auth.passwordResetResend(params.username);
    } catch (err) {
      context.commit('auth/setAuthenticationError', err, { root: true });
    }
  },
  passwordChange: async (context, params) => {
    logger.debug('password change for {}', context.state.user.username);
    context.commit('auth/clearAuthenticationStatus', null, { root: true });
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        user,
        params.currentPassword,
        params.newPassword,
      );
    } catch (err) {
      context.commit('auth/setAuthenticationError', err, { root: true });
    }
  },
  completeNewPassword: async (context, params) => {
    logger.debug('password change for {}', context.state.user.username);
    context.commit('auth/clearAuthenticationStatus', null, { root: true });
    try {
      const user = await Auth.completeNewPassword(

      );
      await Auth.changePassword(
        user,
        params.currentPassword,
        params.newPassword,
      );
    } catch (err) {
      context.commit('auth/setAuthenticationError', err, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
