import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import VCalendar from 'v-calendar';
import Spinner from '@/components/Spinner.vue';
import { Auth } from 'aws-amplify';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './store';
import AuthConfig from './aws-exports';

Auth.configure(AuthConfig);

Vue.use(BootstrapVue);
Vue.use(VCalendar);
Vue.component('Spinner', Spinner);

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default app;
